<template>
    <!-- 项目卡片 -->
    <div class="card-item">
        <div class="img-box">
            <el-image class="img" :src="$imgUrlFilter(img_src, 480)" fit="cover">
                <template #error>
                    <img src="https://s.moimg.net/m/img/placeholder/default_1x1@2x.png" />
                </template>
            </el-image>
        </div>
    </div>
</template>

<script>
export default {
    props: ["img_src"]
}
</script>

<style lang="scss" scoped>
.card-item {
    width: 100%;
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    .img-box {
        padding-top: 100%;
        position: relative;
        .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}
</style>