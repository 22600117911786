<template>
    <div class="box" @click="goProInfo">
        <div class="top-box">
            <CompProCardImg
                :img_src="(item[cardImgKey] || item.wb_logo_1 || item.logo_1x1 || item.logo_4x3 || item.logo || item.img_url)" />
            <div class="title render-line-clamp">{{ item.name }}</div>
        </div>
        <div class="content">
            <div class="company">
                <img :src="$imgUrlFilter(item.user_icon, 40)" fit="cover"
                    onerror='javascript:this.src=https://s.moimg.net/m/img/placeholder/default_1x1@2x.png' />
                <div class="company-name">{{ item.username || (item.user_info ? item.user_info.username : '') ||
                    item.nick_name }}</div>
            </div>
            <!-- 预热 -->
            <template v-if="item.status_code == '1'">
                <div class="start-time-box">
                    <div class="time green">{{ item.start_time }} 开始</div>
                    <div class="appointment-btn">
                        <span class="icon-time icon"></span>
                        <span>预约</span>
                    </div>
                </div>
                <div class="appointment-line gray"></div>
                <div v-if="item.bullish_count" class="tips">{{ item.bullish_count }} 人已预约</div>
            </template>
            <!-- 创意 -->
            <template v-else-if="item.status_code == '4'">
                <div class="creative-tit green">创意阶段</div>
                <div class="creative-line gray"></div>
                <div v-if="item.subscribe_count" class="tips">{{ item.subscribe_count }} 人已看好</div>
            </template>
            <!-- 众筹失败 -->
            <template v-else-if="item.status_code == '2'">
                <div v-if="item.end_time" class="tips tips-bottom">这个项目在 {{ item.end_time.split(' ')[0] }} 结束了，没有达到筹资目标
                </div>
            </template>
            <!-- 众筹终止 -->
            <template v-else-if="item.status_code == '8'">
                <div v-if="item.end_time" class="tips tips-bottom">这个项目在 {{ item.end_time.split(' ')[0] }} 终止了</div>
            </template>
            <!-- 众筹结束--个人愿望 -->
            <template v-else-if="item.status_code == '9'">
                <div v-if="item.end_time" class="tips tips-bottom">这个项目在 {{ item.end_time.split(' ')[0] }} 结束了，感谢所有人支持</div>
            </template>
            <!-- 众筹取消--6:发起者取消、7:摩点取消 -->
            <template v-else-if="item.status_code == '6' || item.status_code == '7'">
                <div v-if="item.end_time" class="tips tips-bottom">这个项目在 {{ item.end_time.split(' ')[0] }} 被发起者 / 摩点取消了
                </div>
            </template>
            <template v-else>
                <div class="progress">
                    <div class="green">￥{{ item.backer_money }}</div>
                    <div class="tips">{{ item.progress }}%</div>
                </div>
                <div class="progress-line gray">
                    <div class="progress-info" :style="`width: ${item.progress + '%'}`"></div>
                </div>
                <div v-if="item.backer_count" class="tips">{{ item.backer_count }} 支持者</div>
                <!-- 众筹成功 -->
                <img class="success-icon" v-if="item.status_code == '3'"
                    src="~@assets/images/project/pro_success_icon.png" />
            </template>
        </div>
    </div>
</template>

<script>
import domain from '@/service/domain'
import CompProCardImg from "@@/compose/proCardImg.vue"
export default {
    components: {
        CompProCardImg
    },
    props: {
        item: {
            type: Object
        },
        cardImgKey: {
            type: String,
            default: ''
        }
    },
    methods: {
        goProInfo() {
            window.open(`${domain.zhongchou}/item/${this.item.id}.html`)

        },
        goHomePage() {
            window.open(`${domain.www}/user/homepage/${this.item.user_id}`)

        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    line-height: 1;

    .top-box {
        cursor: pointer;

        .title {
            line-height: 24px;
            height: 48px;
            font-size: 16px;
            font-weight: bold;
            margin: 10px 15px 20px;
        }
    }

    .content {
        height: 112px;
        padding: 0 15px;
        position: relative;

        .company {
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid #E8E8E8;
                margin-right: 8px;
            }

            &-name {
                max-width: 134px;
                flex: 1;
                width: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #7F7F7F;
            }
        }

        .start-time-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 9px 0 5px;

            .time {
                font-weight: bold;
                font-size: 16px;
                margin-top: 2px;
            }

            .appointment-btn {
                color: #7F7F7F;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 62px;
                height: 28px;
                background: #FFFFFF;
                border-radius: 4px;
                border: 1px solid #B1B1B1;
                cursor: pointer;
                user-select: none;

                .icon {
                    font-size: 18px;
                    margin-right: 3px;
                }
            }
        }

        .appointment-line {
            width: 100%;
            height: 6px;
            margin-bottom: 12px;
        }

        .creative-tit {
            padding: 18px 0 9px;
        }

        .creative-line {
            height: 1px;
            margin-bottom: 13px;
        }

        .progress {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 0 11px;

            &-line {
                height: 6px;
                margin-bottom: 11px;
            }

            &-info {
                height: 6px;
                background-color: #00C4A1;
                max-width: 100%;
            }
        }

        .success-icon {
            width: 60px;
            height: 60px;
            position: absolute;
            right: 15px;
            bottom: 82px;
        }

        .tips {
            color: #7F7F7F;

            &-bottom {
                margin-top: 25px;
                line-height: 21px;
            }
        }

        .gray {
            background-color: #F2F2F2;
        }

        .green {
            color: #00C4A1;
            font-weight: bold;
            font-size: 16px;
        }
    }
}
</style>
