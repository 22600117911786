<template>
    <div class="pro-list">
        <template v-for="(item, index) in list" :key="index">
            <div 
                :class="['pro-item', {'pro-item-last': (index + 1) % 3 == 0}]">
                <CompProCard :item="item" :cardImgKey="cardImgKey" />
            </div>
        </template>
    </div>
</template>

<script>
import CompProCard from "@@/user/proCard.vue"
export default {
    components: {
        CompProCard
    },
    props: ['list', 'cardImgKey']
}
</script>

<style lang="scss" scoped>
.pro-list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;
    .pro-item {
        width: calc((100% - 36px) / 3);
        margin-bottom: 20px;
        margin-right: 18px;
        border: 1px solid #E8E8E8;
        border-radius: 8px;
        &-last {
            margin-right: 0;
        }
    }
}
</style>
